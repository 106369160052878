import { BackEnd } from '../Consts/ApiConsts';
const SetUserProfile = (user)=>{

    // We use id so we can check permissions in the back end.
    sessionStorage.setItem("hash",user._id);
    sessionStorage.setItem('email',user.email);
    sessionStorage.setItem('firstName',user.firstName);
    sessionStorage.setItem('lastName',user.lastName);
    sessionStorage.setItem('degree',user.degree);
    sessionStorage.setItem('isLoggedIn',true);
    window.location.href='/';
}

const UnsetUserProfile = (email)=>{
    sessionStorage.clear();
    fetch(`${BackEnd}users/LogOut/${email}`,{
        method:"GET",
        credentials:"include"
    }).then(response => {
        if(response.ok)
        {
            window.location.href='/';
        }
    })
    
}

const CheckRememberCookie = ()=>{
    if(!sessionStorage['checkedCookie'])
    {
        sessionStorage.setItem("checkedCookie",true);
        // This is the first time the user got into the main page - needs to check for a cookie.
        fetch(`${BackEnd}users/CheckRememberCookie`,{
            method:"GET",
            credentials: "include",
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              }
        })
        .then(response=>{
            if(!response.ok)
            {
              throw Error(response.statusText); 
            }
            return response;
          })
          .then(response=> response.json())
          .then(json=>{
              console.log(json);
              if(json.status === "Success")
              {
                SetUserProfile(json.response);
              }
          })
          .catch(err=>{
            console.log("Failed to check cookie");
          });
    }
    return;
}
export  {CheckRememberCookie, SetUserProfile, UnsetUserProfile};