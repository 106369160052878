import Body from './components/General/Body';
import Navbar from './components/General/Navbar';
import Footer from './components/General/Footer';
import {BrowserRouter as Router, Routes,Route,Navigate } from 'react-router-dom';
import Login from './components/General/Login';
import Register from './components/General/Register';
import About from './components/General/About';
import PersonalInfo from './components/Profile/PersonalInfo';
import Upload from './components/Sums/Upload';
import Search from './components/General/Search';
import SumPage from './components/Sums/SumPage';

/**
 * 
 * @returns 
 * element={<Login/>}
 */

function App() {
  return (
    <div className="App">
          <Router>
            <Navbar/>
              <Routes>
                <Route path='/' element={<Body/>} />
                <Route path='/login'
                  element={sessionStorage['isLoggedIn'] == "true"? <Navigate to="/"/>:<Login/>}
                />
                <Route path="/register" element={<Register/>}/>
                <Route path='/upload' element={<Upload/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/search" element={<Search/>} />
                <Route path="/personalinfo/:email" element={<PersonalInfo/>}/>
                <Route path="/sum/:id" element={<SumPage/>} />
              </Routes>
            <Footer/>
          </Router>
    </div>
  );
}

export default App;
