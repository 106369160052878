import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { BackEnd } from '../../Consts/ApiConsts';
import {FileValidation, UploadValidation} from '../../Functions/FIlesUtils';
let file = "tamir";
const Upload = ()=> {
  const [message,setMessage] = useState({message:"",messageDisplay:"d-none"});
  const [uploading,setUploading] = useState(false);
  const [fileName,setFileName] = useState("");
  const [degree,setDegree] = useState("computerScience");
  const [title,setTitle] = useState("");
  const [course,setCourse] = useState("");
  const [subject,setSubject] = useState("");
  const[descrription,setDescrription] = useState("");
  
  
  function HandleUpload(evt)
  {
    file = evt.target.files[0];
    console.log(file);
    let fileValidation = FileValidation(file);
    
    if(fileValidation.message)
    {
      setMessage({message:fileValidation.message,messageDisplay:"d-block error-msg-wrapper"});
    }
    setFileName(file.name);
  }

  function Upload(evt)
  {
    
    let uploadFileValidation = UploadValidation(file.name,{Title:title,Course:course,Subject:subject,Descrription:descrription});
    if(uploadFileValidation.message)
    {
      setMessage({message:uploadFileValidation.message,messageDisplay:"d-block error-msg-wrapper"});
      return;
    }
    let formData = new FormData();
    formData.append('userEmail',sessionStorage['email']);
    formData.append('hash',sessionStorage['hash']);
    formData.append('file', file);
    formData.append("title",title);
    formData.append("description",descrription);
    formData.append("course",course);
    formData.append("subject",subject);
    formData.append("degree",degree);
    fetch(`${BackEnd}sums/create`,{
      method:"POST",
      credentials: "include",
      body:formData,
  })
    .then(response=>{
      if(!response.ok)
      {
        throw Error(response.statusText);
      }
      return response;
    })
    .then(response => response.json())
    .then(json=>{
      if(json.status == "Failed")
      {
        setMessage({message:json.response,messageDisplay:"d-block error-msg-wrapper"});
      }
      else
      {
        setMessage({message:json.response,messageDisplay:"d-block success-msg-wrapper"})
        setTimeout(()=>{
          document.location.href = '/';
        },1500);
      }
    })
    .catch((err)=>{
      setMessage({message:"Looks like we are having some issues - please try again later.",messageDisplay:"d-block error-msg-wrapper"});

    })

  }

  return (
    <div className='UploadWrapper'>
        <form>
          <input 
            type="file" 
            className="UploadBtn d-none"
            onChange={(evt) => HandleUpload(evt)}
          />
        </form>
        <form className='p-3'>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">Title</label>
            <input type="text" className="form-control" id="SumTitle" aria-describedby="emailHelp" onChange={evt =>{setTitle(evt.target.value)}}/>
          </div>
          <div className="mb-3">
          <label htmlFor="degree" className="form-label">Degree</label>
          <select className="form-select" aria-label="Default select example" onChange={(evt)=>setDegree(evt.target.value)}>
            <option value="computerScience">Computer Science</option>
            <option value="electronicEngineering">Electronic Engineering</option>
            <option value="softwareEngineering">Software Engineering</option>
            <option value="learningTechology">Learning Techology</option>
            <option value="law">Law</option>
            <option value="other">Other</option>
          </select>
        </div>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">Course</label>
            <input type="text" className="form-control" id="SumCourse" aria-describedby="emailHelp" onChange={(evt)=>{setCourse(evt.target.value)}}/>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">Subject</label>
            <input type="text" className="form-control" id="SumSubject" aria-describedby="emailHelp" onChange={evt =>{setSubject(evt.target.value)}}/>
          </div>
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">Descrription</label>
            <textarea type="text" className="form-control" id="SumCourse" aria-describedby="emailHelp" onChange={(evt)=>{setDescrription(evt.target.value)}}/>
          </div>
          
        </form>
        <button className='btn btn-success text-center' id="UploadFile" onClick={(evt)=>{Upload(evt)}}>
            Upload Sum.
        </button>
        <div className='BtnWrapper p-1  content-center text-center w-50 mt-5'>
          <h4 className='mb-4 fs-4 fst-italic fw-bold'>Upload File</h4>
          <button className='btn w-50 btn-outline-primary'
          onClick={(evt)=>{document.getElementsByClassName('UploadBtn')[0].click();}}>
                  <FontAwesomeIcon icon={faPlus} />
          </button>
          <p className='supprted-files fs-4 fst-italic fw-bold'>Supports only PDF files.</p>
        </div>
        <div className={`mt-5 w-25 ${message.messageDisplay}`}>
            <p className='error-msg fs-6 fst-italic fw-bold mt-3'>{message.message}</p>
        </div>
        <div className='text-center mt-5'>
            <h3 className='fs-4 fst-italic fw-bold'>{fileName? `File name: ${fileName}`: ""}</h3>
        </div>
    </div>
  )
}
export default Upload;

/**
 * TODO: This page will handle an upload of a sum. it will show a form where you input the Sum's title, subject,course.
 * This will upload the Sum empty, and the user will be redirected to the sum page, where since its their's sum they will have the Add files option.
 * 
 */