import {useState,useEffect} from 'react'
import { BackEnd } from '../../Consts/ApiConsts';
import Comment from './Comment';

export default function Comments({sumId, commentSubmitted}) {
    const [comments,setComments] = useState([]);
    const [isloading,setIsLoading] = useState(false);

    useEffect(()=>{
        console.log(sumId);
        setIsLoading(true);
        fetch(`${BackEnd}comments/sumcomments/${sumId}`)
        .then(response=>{
            if(!response.ok)
            {
                throw (response.statusText);
            }
            return response;
        })
        .then(response=>response.json())
        .then(json => {setComments(json.response);setIsLoading(false);})
        .catch(err=>{
            console.log(err);
            setIsLoading(false);
        })
    },[commentSubmitted])
  return (
    <div>
        {isloading? <p>Getting Comments</p>:
            comments.map((comment)=>{
                return (
                    <Comment
                        key={comment._id}
                        sumId={sumId}
                        firstName={comment.firstName}
                        lastName={comment.lastName}
                        comment={comment.comment}
                    />
                )
            })
        }
    </div>
  )
}
