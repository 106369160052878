import Sums from '../Sums/Sums';
import { CheckRememberCookie } from '../../Functions/UserProfile';
import { Helmet } from 'react-helmet';
const Body = ()=>
{
    // Checking if a rememberMe cookie is on.
    CheckRememberCookie();
        return(
            <div>
                <h1 className="text-center mt-3 mb-5 fs-2 fst-italic fw-bolder">Top sums for you</h1>
                <Sums 
                    userhash={sessionStorage['hash']} 
                    location="body"
                />
            </div>
        )
}

export default Body;