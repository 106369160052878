const FileValidation = (file)=>
{

    const AllowedEndings = ["pdf", "png", "jpg", "jpeg", "docx"];
    let fileEnding = file.name.split('.')[1];
    if(!AllowedEndings.includes(fileEnding.toLowerCase()))
    {
        return {status:"Failed",message:`The file type ${fileEnding} isn't supported`};
    }
    else if(file.size > 10000000)
    {
        return {status:"Failed",message:`The file ${file.name} is over 10MB`};
    }
    return {status:"Success"};
}

const UploadValidation = (fileName,sumData)=>{
    if(fileName =="" || fileName == undefined)
    {
        return {status:"Failed", message:"Please upload a pdf file."}
    }
    if(sumData.Title == "" || sumData.Title == undefined )
    {
        return {status:"Failed", message: "Please insert a title."}
    }
    if(sumData.Course == "" || sumData.Title == undefined )
    {
        return {status:"Failed", message: "Please insert a course name."}
    }
    if(sumData.Subject == "" || sumData.Title == undefined )
    {
        return {status:"Failed", message: "Please insert a subject."}
    }
    if(sumData.Descrription == "" || sumData.Descrription == undefined )
    {
        return {status:"Failed", message: "Please Describe the video."}
    }
    return {status:"Success"};
}

export {FileValidation,UploadValidation};