import {useState, useEffect} from 'react'
import Sum from './Sum';
import { BackEnd } from '../../Consts/ApiConsts';
export default function Sums({userhash,location}) {
    const [sums,setSums] = useState([]);
    const [loading,setLoading] = useState(false);
    useEffect(()=>{
        setLoading(true);
        fetch(`${BackEnd}sums` ,{
            method:"POST",
            credentials: "include",
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body:JSON.stringify({hash:userhash, location:location})
        }).
        then(response =>{
            if(!response.ok)
            {
                throw(response.statusText);
            }
            return response;
        }).
        then(response => response.json()).
        then(json=>{setSums(json.response);setLoading(false);}).
        catch(err=>{
            console.log(err);
        })
    },[]);
  return (
        <div>
          {loading ? <h1>Loading Relevant sums:</h1> :
              <div>
                  <div className='row p-3'>
                      {sums.map(element => {
                          return (
                              <div className='col-lg-4 col-md-6 col-sm-12 mt-5'>
                                  <Sum
                                      key={element._id}
                                      sumID={element._id}
                                      title={element.Title}
                                      degree={element.Degree}
                                      course={element.Course}
                                      subject={element.Subject}
                                      email={element.User.email}
                                  />
                              </div>
                          )
                      })}
                  </div>
              </div>}
        </div>
  )
}
