import {useState} from 'react'
import { Link} from "react-router-dom";
import {SetUserProfile} from '../../Functions/UserProfile';
import {PORT,BackEnd} from '../../Consts/ApiConsts';

export default function Login() {
  //#region Setting variables
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [rememberMe,setRememberMe] = useState(false);
  const [errorMSG,setErrorMSG] = useState("");
  const [errorDiv,setErrorDiv] = useState("d-none");
  let emailPattern = 	/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  //#endregion Setting variables
  function Validation()
  {
    if(email.length < 1 || !emailPattern.test(email))
    {
      setErrorDiv("d-block");
      setErrorMSG("Please use a valid email.");
      return false;
    }
    else if(password.length < 6)
    {
      setErrorDiv("d-block");
      setErrorMSG("Password too short.");
      return false;
    }
    setErrorDiv("d-none");
    setErrorMSG("");
    return true;
  }
  function LogIn()
  {

    fetch(`${BackEnd}users/login`,{
      method:'POST',
      credentials: "include",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email:email,password:password,rememberMe:rememberMe})
    })
    .then(response=>{
      if(!response.ok)
      {
        throw Error(response.statusText); 
      }
      return response;
    })
    .then(response=> response.json())
    .then(json=>{
      if(json.status == "Failed")
      {
        setErrorDiv("d-block");
        setErrorMSG(json.response);
      }
      else
      {
        SetUserProfile(json.response);
        
      }
    })
    .catch(err=>{
      setErrorDiv("d-block");
      setErrorMSG("Looks like were having issues with our system. Please try again later.");
    });
  }
  function HandleLogin(evt)
  {
      evt.preventDefault();
     
      if(Validation())
      {
        LogIn();
      }
  }
  return (
    <div className="info-register-form-wrapper"> 
      <h1 className='align-middle p-4 text-center'>Login</h1>
      <form className="info-register-form p-4 mt-2 w-50 content-center">
        <div className={errorDiv}>
            <p className='border border-danger border-3 rounded text-dark text-center fw-bold fst-italic fs-6'>{errorMSG}</p>
        </div>
        <div className="mb-3">
          <label htmlFor="Email" className="form-label">Email address</label>
          <input 
            type="email"
            className="form-control"
            id="Email" 
            aria-describedby="emailHelp"
            onChange={(evt)=>{setEmail(evt.target.value)}}
            />
        </div>
        <div className="mb-3">
          <label htmlFor="Password" className="form-label">Password</label>
          <input 
            type="password" 
            className="form-control"  
            id="Password"
            onChange={(evt)=>{setPassword(evt.target.value)}}
            />
        </div>
        <div className="mb-3 form-check">
          <input 
          type="checkbox" 
          className="form-check-input" 
          id="rememberMe"
          onChange={(evt)=>{setRememberMe(prevRememberMe => !prevRememberMe)}}
          />
          <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
        </div>
        <button type="submit" className="btn btn-primary" onClick={(evt)=>HandleLogin(evt)}>Log-in</button>
      </form>
      <p className="mt-3 text-center fst-italic fw-bold">Don't have an account yet? <Link to="/register">Register</Link></p>
    </div>
  )
}