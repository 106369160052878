import {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BackEnd } from '../../Consts/ApiConsts';
import Comments from './Comments';
import { TabTitle } from '../../Functions/GeneralFunctions';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { calculateNewValue } from '@testing-library/user-event/dist/utils';

export default function SumPage({title}) {
  
  const {id} = useParams();
  const [loading,setLoading] = useState(false);
  const [sum,setSum] = useState({});
  const [downloadBtn,setDownLoadBtn] = useState(true);
  const [pdfFile,setPdfFile] = useState();
  const [comment,setComment] = useState("");
  const [commentSubmitted,setCommentSubmitted] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [likeButton,setLikeButton] = useState("")
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  function SubmitComment(evt)
  {
    setComment(evt.target.value);
    if(comment == "")
    {
      return;
    }
    setCommentSubmitted(false);
    let firstname = sessionStorage['isLoggedIn'] == 'true'? sessionStorage['firstName']:"Guest";
    let lastname = sessionStorage['isLoggedIn'] == 'true'? sessionStorage['lastName']:"";
      fetch(`${BackEnd}comments/create/${sum._id}`,{
        method:"POST",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({SumID:sum._id,firstName:firstname,lastName:lastname,comment:comment})
      })
      .then(response=>{
        if(!response.ok)
        {
          throw (response.statusText);
        }
        return response;
      })
      .then(response=>response.json())
      .then(json=>{
        if(json.status === "Failed")
        {
          console.log(json.response);
        }
        else
        {
          setComment("");
          document.getElementById("Comment").value = "";
          setCommentSubmitted(true);
        }
      })
      .catch(err=>{
        console.log(err);
      })
  }
  function HandleReport()
  {
    alert("We're sorry for the troubles! an email was sent to the admins.")
    document.location.href = '/';
  }
function RemoveDownloadBtn()
{
    if(downloadBtn)
    {
      let downLoadBtn = document.getElementsByClassName("rpv-core__minimal-button");
      if(downLoadBtn !== undefined && downLoadBtn !== null)
      {
        downLoadBtn[12].remove();
        setDownLoadBtn(false);
      }
    }
}
  function HandleLike()
  {
    fetch(`${BackEnd}sums/liked/${sessionStorage.hash}/${id}`);
    setLikeButton("float-start btn btn-secondary disabled");
  }
  useEffect(()=>{
    setLoading(true);
    fetch(`${BackEnd}sums/${id}/${sessionStorage.hash}`)
    .then(response => {
      if(!response.ok)
      {
        throw("Error in the response.");
      }
      return response;
    })
    .then(response => response.json())
    .then(json => {
        setLoading(false);
        if(json.status == "Failed")
        {
          throw(json.response);
        }
        setSum(json.response);
        let actualSum = new Uint8Array(json.pdfFile.data);
        setPdfFile(actualSum);
        setIsLiked(json.isLiked);
        setLikeButton(sessionStorage.hash && !json.isLiked?"float-start btn btn-success" :"float-start btn btn-secondary disabled")
        TabTitle(json.response.Title);
        
    })
    .catch(err=>{
      console.log(err);
    })
  },[])



  return (
    loading? <h1>Loading sum...</h1>:
    <div className='test' id="tamir"  onClick={console.log("hello")}>
      <input hidden onLoad={RemoveDownloadBtn}></input>
        <div className="Pdf-holder p-5 sm-w-100 text-center" >
          <h1 className='fs-5 fst-italic fw-bolder'>{sum.Title}</h1>
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.js">
            <div style={{ height: '750px' }} >
                <Viewer
                    fileUrl={pdfFile? pdfFile:"none"}
                    plugins={[
                      defaultLayoutPluginInstance
                    ]}
                />
            </div>
          </Worker>
          <div className='mt-2 control-buttons-wrapper'>
              <button className='btn btn-danger float-end' onClick={HandleReport}>Report</button>
              {sum.IsHasMassvidVideo?<a className='btn btn-primary' href={sum.MassvidLink} target="_blank">Click here for the Video</a>:""}
              <button type='button' className={likeButton} id='Subscribe' onClick={HandleLike}>Like <FontAwesomeIcon icon={faThumbsUp}/></button>
              
          </div>
        
        </div>
        <div className='comment-section mt-5 p-3'>
            <div className='comment-section-controllers'>
              <textarea className='w-100 form-control' id="Comment" placeholder='Leave a comment here...' onChange={evt =>{setComment(evt.target.value)}}></textarea>
              <button type='button' className='btn btn-outline-primary mt-2' onClick={(evt)=>SubmitComment(evt)}>Submit comment</button>
            </div>
            <div className='comment-section-comments mt-5'>
                 <Comments sumId={id} commentSubmitted={commentSubmitted}/>
            </div>
        </div>
    </div>
  )
  
}
