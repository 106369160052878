import { useState, useEffect } from "react"
import { BackEnd } from '../../Consts/ApiConsts';
export default function PersonalInfoDetails({email,degree,firstname,lastname}) {
  const [isSameUser, setIsSameUser] = useState(false);
  useEffect(()=>{
    if(sessionStorage['isLoggedIn'])
    {
      fetch(`${BackEnd}users/issameuser`,{
        method: "POST",
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body:JSON.stringify({email:email,hash:sessionStorage['hash']})
  
      })
      .then(response =>{
        if(!response.ok)
        {
          throw(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(json => setIsSameUser(json.response))
      .catch(err => {console.log(err)});
    }
  },[])
  return (
    <div>
        <h4 className='fs-1 fst-italic fw-bolder text-center'>{email}</h4><hr/>
        <ul className='list-group text-center'>
            <li className='list-group-item  list-group-item-primary fs-4 fst-italic fw-bolder'>My name is {firstname + " " + lastname}</li>
            <li className='list-group-item  list-group-item-primary fs-4 fst-italic fw-bolder'>What am i studying - {degree}</li>
            {isSameUser?<li className='list-group-item  list-group-item-primary fs-4 fst-italic fw-bolder'><a className='btn btn-outline-success w-50 fs-4 fst-italic fw-bolder' href='/upload'>Upload sum</a></li>:""}
        </ul>
    </div>
  )
}
