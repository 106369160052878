import {useState,useEffect} from 'react';
import Sums from '../Sums/Sums';
export default function PersonalInfoSums({hash,email}) {

  return (
    <div>
        <Sums
            userhash={hash}
            location="personal"
        />
    </div>
  )
}
