import React from 'react';
import { useParams } from 'react-router-dom';
export default function Sum({sumID,title,degree, course,subject,email}) {

  function HandleClick(evt){
      //TODO - send the user to the sum with the sum id.
      document.location.href = `/sum/${sumID}`;
  }
  
  return (
    <div className="SumCard text-center  p-3 " onClick={evt=>HandleClick(evt)}>
        <h3 className='mb-4'>{title}</h3>
        <h4 className='mb-4'>{degree}</h4>
        <h5 className='mb-4'>{course}</h5>
        <h5 className='mb-4'>{subject}</h5>
        <a className=' btn btn-outline-primary' href={`/personalinfo/${email}`}><h5>{email}</h5></a>
    </div>
  )
}