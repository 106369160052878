import React from 'react'
export default function About() {
  return (
    <div>

        <div className='AboutUsWrapper row p-5 m-3 text-center'> 
                   
            <div className='col-md-4 col-sm-12 p-3'>
                <div className="card rounded-3  border-3 " style={{minHeight:"25rem"}}>
                    {/* <img src="./images/Tamir-image.JPG" className="card-img-top" alt="..." style={{maxWidth:"800px",maxHeight:"500px"}}/> */}
                    <div className="card-body">
                        <h5 className="card-title">Tamir Gonen</h5>
                        <p className="card-text">Founder, owner and CEO of MassCorp - Developer of Massvid.com and MassReads.com </p>
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">Martial arts fan</li>
                        <li className="list-group-item">Guitar player</li>
                        <li className="list-group-item">Loves the horror genre</li>
                    </ul>
                    <div className="card-body">
                        <a href="https://www.linkedin.com/in/tamir-gonen-10667919b/" className="card-link" target="_blank">Linkedin</a>
                        <a href="https://www.facebook.com/tamir.gonen.7/" className="card-link" target="_blank">Facebook</a>
                    </div>
                </div>
            </div>
            <div className='col-md-4 col-sm-12'></div>
            <div className='col-md-4 col-sm-12 p-3'>
                <div className="card border-3" style={{minHeight:"25rem"}}>
                    {/* <img src="..." className="card-img-top" alt="..."/> */}
                    <div className="card-body">
                        <h5 className="card-title">Ron Shalom</h5>
                        <p className="card-text">Co.Founder of MassCorp. The main content creator,Data scientist and analyst.</p>
                    </div>
                    <ul className="list-group list-group-flush">
                        <li className="list-group-item">Football is life</li>
                        <li className="list-group-item">loves Jogging</li>
                        <li className="list-group-item">Teacher in heart and soul</li>
                    </ul>
                    <div className="card-body">
                        <a href="https://www.linkedin.com" className="card-link">Ron's Linkedin</a>
                        <a href="https://www.facebook.com" className="card-link">Ron's Facebook</a>
                    </div>
                </div>
            </div>
        </div>
        <div className='AboutUs text-wrap text-break fs-4 fst-italic fw-bold lh-lg p-3 text-center'>
            <p>
                We are Masscorp, our goal is to make everyone capable of getting a respectable degree
                in whatever field they are interested.
            </p>
            <p>Our products are:</p>
            <p>1) Massvid.com - a video oriented website where you learn everything via watching videos online.</p>
            <p>you can watch them anywhere, anytime.
               The courses are very high level and we have multiple same courses with different lecturers - because we aim to hit the widest range of students out there.
            </p>
            <p>2) MassReads - a website where you can find ANYTHING written, need a sum? forgot how to integrate cotan?</p>
            <p>this is the perfect place for you. just search for the subject and vualla! you find thousands of papers with everything you need to know about the subject you need.</p>
            <p>Free of charge, by the way, and you can upload any sum or written matirel you want.</p>
            <p>oh, Did we mention you will get <strong>paid</strong> for it?</p>    
        </div>
        
    </div>
  )
}
