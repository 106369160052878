import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {UnsetUserProfile} from '../../Functions/UserProfile';
const Navbar = ()=>
{
  const [query,setQuery] = useState("");
  function HandleSearch()
  {
    document.location.href = `/search?qs=${query}`;
  } 
    return(
<nav className="navbar navbar-expand-lg navbar-dark bg-dark p-3 sticky-top">
  <div className="container-fluid">
    <a href="/" className="navbar-brand">MassReads</a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <form className="d-flex mt-1 w-50" onSubmit={e=>{e.preventDefault();HandleSearch()}}>
        <input 
          className="form-control  searchbar" 
          type="text" 
          placeholder="Look for a course, subject or even a specific topic." 
          onChange={evt=>{setQuery(evt.target.value)}}
        />
       
      </form>
      <ul className="navbar-nav  mb-lg-0 ms-auto">
        <li className="nav-item me-3">
          {
            sessionStorage['isLoggedIn'] == 'true'? 
            <a href={`/personalinfo/${sessionStorage['email']}`} className="nav-link active">Profile</a>
            :
            <a href="/login" className="nav-link active">Register/Login</a>
          }
        </li>
        <li className="nav-item me-3">
          <a href="https://www.youtube.com/watch?v=VzQHet_l3Sc&t=2s&ab_channel=massvid" target="_blank" className="nav-link active">Guide</a>
        </li>
        <li className="nav-item me-3">
          <a className="nav-link active" aria-current="page" href="https://www.Massvid.com" data-bs-toggle="tooltip" data-bs-placement="left" title="The best way to learn for your degree" target="_blank">Massvid</a>
        </li>
        <li className="nav-item me-3">
          <a className="nav-link active" href="https://www.facebook.com/massvidcorp" data-bs-toggle="tooltip" data-bs-placement="left" title="Our lovely facebook page"  target="_blank">Facebook Page{<FontAwesomeIcon icon="fa-facebook-f"/>}</a>
        </li>
        <li className="nav-item me-3">
          {
            sessionStorage['isLoggedIn'] == 'true'? <a className="nav-link active" href="#" onClick={()=>UnsetUserProfile(sessionStorage['email'])}>Sign out</a>:""
          }
        </li>
      </ul>

    </div>
  </div>
</nav>
    )
}

export default Navbar;