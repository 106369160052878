import Sum from '../Sums/Sum';
import {useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { BackEnd } from '../../Consts/ApiConsts';
import { TabTitle } from '../../Functions/GeneralFunctions';
const Search = ()=>
{
    // const {qs} = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const qs = queryParams.get('qs');
    const [sums,setSums] = useState([]);
    const [loading,setLoading] = useState(false);
    const [headline,setHeadLine] = useState(`Search Results for ${qs}`);
    //localStorage.removeItem('query');
    useEffect(()=>{
        setLoading(true);
        fetch(`${BackEnd}sums/search`,{
            method:"POST",
            credentials: "include",
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body:JSON.stringify({searchQuery:qs})
        }).
        then(response =>{
            if(!response.ok)
            {
                throw(response.statusText);
            }
            return response;
        }).
        then(response => response.json()).
        then(json=>{
            setSums(json.response);
            setLoading(false);
            TabTitle(`Search results for - ${qs}`);
        }).
        catch(err=>{
            console.log(err);
        })
    },[]);
        return(
            
            loading? <h1>Loading Relevant sums:</h1>:
            <div>
                <h1 className="text-center mt-3 mb-5">{headline}</h1>
                <div className='row p-3'>
                    {sums.map(element => {
                        return( 
                        <div className='col-lg-4 col-md-6 col-sm-12 mt-5'>
                            <div className="sums-wrapper" >
                                <Sum 
                                      key={element._id}
                                      sumID={element._id}
                                      title={element.Title}
                                      degree={element.Degree}
                                      course={element.Course}
                                      subject={element.Subject}
                                      email={element.User.email}
                                />
                            </div>
                        </div>
                        )
                    })}
                </div>
            </div>

        )
    
}

export default Search;