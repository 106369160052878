import React from 'react'

export default function Comment({sumId,firstName,lastName,comment}) {
  return (
    <div className='comment mt-3'>
        <h5 className='comment-header'>{`${firstName} ${lastName}`}</h5>
        <textarea className='form-control' readOnly={true}>{comment}</textarea>
    </div>
  )
}
