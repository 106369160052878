import {useState} from 'react'
import { Link } from "react-router-dom";
import {SetUserProfile} from "../../Functions/UserProfile";
import { BackEnd } from '../../Consts/ApiConsts';
export default function Register() {

  //#region Setting Variables
  const [email,setEmail] = useState("");
  const [password,setPassword] = useState("");
  const [firstName,setFirstName] = useState("");
  const [lastName,setLastName] = useState("");
  const [degree,setDegree] = useState("computerScience");
  const [errorMSG,setErrorMSG] = useState("");
  const [errorDiv,setErrorDiv] = useState("d-none");
  let emailPattern = 	/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  //#endregion Setting Variables
  function Validation()
  {
    if(email.length < 1 || !emailPattern.test(email))
    {
      setErrorDiv("d-block");
      setErrorMSG("Please fill in a valid email.");
      return false;
    }
    else if(firstName.length < 1)
    {
      setErrorDiv("d-block");
      setErrorMSG("Please fill in your first name.");
      return false;
    }
    else if(lastName.length < 1)
    {
      setErrorDiv("d-block");
      setErrorMSG("Please fill in your last name.");
      return false;
    }
    else if(password.length < 6)
    {
      setErrorDiv("d-block");
      setErrorMSG("Password too short.");
      return false;
    }
    setErrorDiv("d-none");
    setErrorMSG("");
    return true;
  }
  function Register()
  {
    fetch(`${BackEnd}users/create`,{
      method:'POST',
      headers:{
        "Content-type":"application/json; charset=UTF-8"
      },
      body:JSON.stringify({email:email,password:password,firstName:firstName,lastName:lastName,degree:degree})
    })
    .then(response => {
      if(!response.ok)
        {
            throw Error(response.statusText);
        }
        return response;
    })
    .then(response => response.json())
    .then(json =>
      {
        if(json.status === "Failed")
        {
          setErrorDiv("d-block");
          setErrorMSG(json.response);
        }
        else
        {
          // The response is the user itself, if  the registration was successful - we will sign them in.
          SetUserProfile(json.response);
        }
      })
      .catch(err=>{
        setErrorDiv("d-block");
        setErrorMSG("Looks like were having issues with our system. Please try again later.");
      })
  }
  function HandleRegistration(evt)
  {
      evt.preventDefault();
      if(Validation())
      {
        Register();
      }
  }
  return (
    <div>
      <h1 className='align-middle p-4 text-center'>Register</h1>
      <div className={errorDiv}>
            <p className='border border-danger border-3 rounded text-dark text-center fw-bold fst-italic fs-6'>{errorMSG}</p>
      </div>
      <form className="info-register-form p-4 mt-2 w-50 content-center">
        <div className="mb-3">
          <label htmlFor="Email" className="form-label">Email address</label>
          <input 
          type="email"
          className="form-control" 
          id="Email" 
          aria-describedby="emailHelp"
          onChange={(evt)=>setEmail(evt.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="Password" className="form-label">Password</label>
          <input 
          type="password" 
          className="form-control"  
          id="Password"
          onChange={(evt)=>setPassword(evt.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="firstName" className="form-label">First name</label>
          <input 
          type="text" 
          className="form-control"  
          id="firstName"
          onChange={(evt)=>setFirstName(evt.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="lastName" className="form-label">Last name</label>
          <input 
          type="text" 
          className="form-control"  
          id="lastName"
          onChange={(evt)=>setLastName(evt.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="degree" className="form-label">Degree</label>
          <select className="form-select" aria-label="Default select example" onChange={(evt)=>setDegree(evt.target.value)}>
            <option value="computerScience">Computer Science</option>
            <option value="electronicEngineering">Electronic Engineering</option>
            <option value="softwareEngineering">Software Engineering</option>
            <option value="learningTechology">Learning Techology</option>
            <option value="law">Law</option>
            <option value="other">Other</option>
          </select>
        </div>
        <button type="button" className="btn btn-primary rounded RegBtn" onClick={evt=>HandleRegistration(evt)}>Register</button>
      </form>
      <p className="mt-3 text-center fst-italic fw-bold">Already have an account? <Link to="/login">Log-in</Link></p>
    </div>
  )
}