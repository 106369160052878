import {useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import PersonalInfoDetails from './PersonalInfoDetails';
import PersonalInfoSums from './PersonalInfoSums';
import { BackEnd } from '../../Consts/ApiConsts';
export default function PersonalInfo() {
    const {email} = useParams()
    const [user,setUser] = useState(null);
    useEffect(()=>{
        //setting the user:
        
        fetch(`${BackEnd}users/${email}`)
        .then(response => {
            if(!response.ok)
            {
                throw (response.statusText);
            }
            return response;
        })
        .then(response => response.json())
        .then(json => setUser(json.response))
        .catch(err=>{
            console.log("got an error");
        })
    },[])
  return (
      user == null? <h1>Loading settings</h1>:
    <div>
        <div className="row p-3 ">
            <div className='col-12'>
                <PersonalInfoDetails
                    email={user.email}
                    degree={user.degree}
                    firstname={user.firstName}
                    lastname={user.lastName}
                />
            </div>
            <h3 className='text-center fs-1 fst-italic fw-bolder mt-5'>Uploads</h3>
            <div className=''>
                <PersonalInfoSums hash = {user.hash} email={user.email}/>
            </div>

        </div>
    </div>
  )
}
